import React from "react";

import { PublicLayout } from "../layouts/public"

const Page = ({ pageContext }) => {
    if(!pageContext) return null
    const { air } = pageContext
    
    const content = {}
    air.htmlTitle && (content.htmlTitle = air.htmlTitle)
    air.title && (content.pageTitle = air.title)
    if(air.publishDate && air.publishDate !== "Invalid date") {
        content.publishDate = air.publishDate
    }
    air.introTagline && (content.pageTagline = air.introTagline)
    air.introBody && (content.pageBody = air.introBody)
    air.introCtaText && (content.pageCtaText = air.introCtaText)
    air.introCtaTagline && (content.pageCtaTagline = air.introCtaTagline)
    air.introCtaTarget && (content.pageCtaTarget = air.introCtaTarget)
    air.imageUrl && (content.pageImageUrl = air.imageUrl)
    air.imageAlt && (content.pageImageAlt = air.imageAlt)



    const sections = air.sections ? air.sections : null



    return (
        <PublicLayout
            {...content}
            sections={sections}
        />
    )

}

export default Page